<template>
  <div class="content container post">
    <h1>Living In Excellence</h1>

    <!-- NO GROUP YET -->
    <template v-if="!has_group && !loading">
      <b-alert
        show
        variant="info"
      >
        You have not been assigned a group yet. You will receive an email
        once you have been placed.
      </b-alert>
    </template>

    <!-- VIDEOS -->
    <template v-if="posts.length && has_group">
      <div
        v-for="post in posts"
        :key="post.id"
      >
        <p
          class="mt-5 mb-2 h4"
        >
          <!-- {{ getCurrentWeek(post.created) }} -->
        </p>
        <b-card
          class="overflow-hidden"
          no-body
        >
          <template v-if="post.category === 'video_upload'">
            <iframe
              :key="`post${post.id}`"
              :src="`https://player.vimeo.com/video/${post.vimeo_video_id}`"
              frameborder="0"
              webkitAllowFullScreen
              mozallowfullscreen
              allowFullScreen
            />
          </template>
          <template v-if="post.category === 'zoom_call_link'">
            <a
              class="ml-3 h5 py-3"
              :href="post.zoom_call_link"
              target="_blank"
            >{{ post.zoom_call_link }}</a>
          </template>
          <template v-if="post.category === 'announcement'">
            <div class="mb-3" />
          </template>
          <b-card-text class="ml-3">
            <div class="d-flex">
              <b-img
                :src="post.created_by.profile_picture ? post.created_by.profile_picture : require('../../assets/avatar-default.png')"
                rounded="circle"
                width="50"
                height="50"
                alt
              />
              <div class="ml-4">
                <p class="h5 mb-0">
                  {{ post.title }}
                </p>
                <small>uploaded by coach
                  <a href="">
                    {{ post.created_by.first_name }} {{ post.created_by.last_name }}
                  </a>
                </small>
              </div>
            </div>
          </b-card-text>
          <b-card-text
            v-if="post.description !== ''"
            class="mx-3"
          >
            {{ post.description }}
          </b-card-text>
          <template v-slot:footer>
            <small class="text-muted">{{ dateFromNow(post.created) }}</small>
          </template>
        </b-card>
      </div>
    </template>

    <!-- NO VIDEOS -->
    <div v-if="loaded && !posts.length && !errors && has_group">
      No posts yet.
    </div>

    <!-- LOADING -->
    <div class="d-flex justify-content-center mt-5">
      <b-spinner
        v-if="loading"
        variant="info"
        style="width: 80px; height: 80px;"
      />
    </div>

    <!-- ERROR -->
    <div v-if="errors">
      <b-alert
        show
        variant="danger"
      >
        Unable to load posts. Please test your connection and refresh the page.
      </b-alert>
    </div>
  </div>
</template>

<script>
import moment from 'moment';

export default {
  name: 'Profile',
  data() {
    return {
      has_group: false,
      loading: true,
      loaded: false,
      errors: false,
      posts: [],
      last_week: '',
    };
  },
  computed: {
    userData() {
      return this.$store.state.userData;
    },
  },
  mounted() {
    this.fetchAssignedGroups();
    this.fetchVideos();
  },
  methods: {
    async fetchAssignedGroups() {
      this.loading = true;

      const url = `${process.env.VUE_APP_API_URL}users/${this.userData.id}/assigned_living_in_excellence_group/`;

      let response;

      try {
        response = await fetch(
          url,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Token ${this.userData.drf_token}`,
            },
          },
        );
      } catch (e) {} // eslint-disable-line no-empty

      if (response && response.status === 200) {
        const responseJson = await response.json();
        this.has_group = responseJson;
      } else {
        this.errors = true;
      }
    },
    async fetchVideos() {
      this.loading = true;

      const url = `${process.env.VUE_APP_API_URL}programs/living_in_excellence/posts/`;

      let response;

      try {
        response = await fetch(
          url,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Token ${this.userData.drf_token}`,
            },
          },
        );
      } catch (e) {} // eslint-disable-line no-empty

      this.loading = false;
      this.loaded = true;

      if (response && response.status === 200) {
        const responseJson = await response.json();
        this.posts = responseJson;
      } else if (response && response.status === 403) {
        this.$router.push({ name: 'enroll_new' });
      } else {
        this.errors = true;
      }
    },
    getCurrentWeek(d) {
      const currentWeek = `${this.getMondayOfCurrentWeek(d)}-${this.getSundayOfCurrentWeek(d)}`;
      return currentWeek;
    },
    getMondayOfCurrentWeek(d) {
      const day = moment(d);
      const monday = day.day(0);
      return monday.format('MMM Do');
    },
    getSundayOfCurrentWeek(d) {
      const day = moment(d);
      const sunday = day.day(7);
      return sunday.format('MMM Do');
    },
  },
};
</script>

<style lang="scss" scoped>
.post {
  margin: 0 auto;
  max-width: 500px;

  iframe {
    margin-top: -10px;
    width: 100%;
    height: 300px;
  }
}
</style>
